@charset "utf-8";

// my custom css variables
:root {
  --scms-primary-blue: rgb(0, 159, 227);
  --scms-green: rgb(134, 188, 37);
  --scms-yellow: rgb(253, 195, 0);
  --scms-orange: rgb(242, 140, 0);
  --scms-red: rgb(234, 79, 61);

  --scms-dark-grey: rgb(50, 50, 50);

  --scms-box-shadow: 3.5px 3.5px 7px rgba(0, 0, 0, 0.12),
    2.5px 2.5px 2.5px rgba(0, 0, 0, 0.06);
  --scms-box-shadow-small: 2px 2px 4px rgba(0, 0, 0, 0.12),
    1px 1px 1px rgba(0, 0, 0, 0.02);

  --scms-semi-bold: 600;
  --scms-bold: 700;
}

// TODO: Import only what you need from Bulma
// @import '~bulma/bulma.sass';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';
@import '~bulma/sass/grid/tiles.sass';
@import '~bulma/sass/elements/container.sass';
@import '~bulma/sass/elements/title.sass';
@import '~bulma/sass/components/navbar.sass';
@import '~bulma/sass/helpers/typography.sass';
